import { axiosInstance } from './axios';

/**
 * addFavoriteProfil
 * @param {number} candidateId
 * @return {Promise}
 * @throws {Error}
 */
const addFavoriteProfil = async (candidateId) => {
  try {
    const response = await axiosInstance.post(`/best_profils/${candidateId}/`);
    return response;
  } catch (error) {
    throw new Error('add candidate to favorite failed');
  }
};

/**
 * removeFavoriteProfil
 * @param {number} favorite apply id
 * @return {Promise}
 * @throws {Error}
 */
const removeFavoriteProfil = async (FavoriteApplyId) => {
  try {
    const response = await axiosInstance.delete(
      `/best_profils/remove_favorite_user/${FavoriteApplyId}/`
    );
    return response;
  } catch (error) {
    throw new Error('remove candidate from favorite failed');
  }
};

/**
 * isCandidateFavoriteById
 * @return {Array}
 * @throws {Error}
 */
// Cette fonction renvoie les détails complets des candidats favoris
const isCandidateFavoriteById = async () => {
  try {
    const response = await axiosInstance.get(
      `/best_profils/list_user_favorites/`
    );
    // Retourner un tableau avec tous les profils favoris avec leurs détails
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des favoris:', error);
    return [];
  }
};

export { addFavoriteProfil, isCandidateFavoriteById, removeFavoriteProfil };
