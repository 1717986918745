<template>
  <div class="messagerie-layout">
    <!-- Contenedor del chat -->
    <Chat :isInLayout="true" />
  </div>
</template>

<script>
  import Chat from '../../chat/ChatApp.vue';

  export default {
    name: 'MessagerieLayout',
    components: {
      Chat,
    },
  };
</script>

<style scoped>
  .messagerie-layout {
    position: sticky;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
    max-height: 0px;
    padding-right: 2rem;
    z-index: 10;
  }
</style>
