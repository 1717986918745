<template>
  <img
    :src="
      user.photo
        ? getImgPath(user.photo)
        : require('@/assets/search/search-page-jobcard-emptylogo-icon.jpg')
    "
    :alt="user.first_name"
    class="profile-photo"
    :style="{ '--avatar-width': width + 'px' }"
  />
</template>

<script>
  import getImgPath from '@/utils/imgpath.js';

  export default {
    name: 'UserAvatar',

    props: {
      user: {
        type: Object,

        required: true,
      },

      width: {
        type: Number,

        default: 100,
      },
    },

    methods: {
      getImgPath,
    },
  };
</script>

<style scoped>
  .profile-photo {
    width: var(--avatar-width);

    height: var(--avatar-width);

    border-radius: 50%;

    object-fit: cover;
  }
</style>
