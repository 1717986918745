import { isCandidateFavoriteById } from '@/services/favoriteProfil.service';
import { getUser } from '../../services/account.service';

export default {
  state: {
    isLoggedIn: false,
    posts: [],
    comments: [],
    user: {
      role: '',
      conversation: [],
      alerte: [],
      favorie_job: [],
    },
  },
  mutations: {
    updateUser(state, { type, payload }) {
      if (type) {
        state.user[type] = [...state.user[type], payload];
      } else {
        state.user = payload;
      }
    },
    updateUserRole(state, role) {
      state.user.role = role;
    },
    resetUser(state) {
      state.user = { role: '' };
    },
    deleteUserPropreties(state, { type, payload }) {
      state.user[type] = state.user[type].filter((list) => list.id !== payload);
    },
    deleteUser(state) {
      console.log("Réinitialisation de l'utilisateur");
      state.user = {};
    },

    // alert
    addAlert(state, payload) {
      state.user.alerte = [...state.user.alerte, payload];
    },
    updateAlert(state, payload) {
      if (!Array.isArray(state.user.alerte)) {
        state.user.alerte = [];
      }

      // Créer une copie de state.user.alerte
      const alerteCopy = [...state.user.alerte];

      // Trouver l'index de l'alerte à mettre à jour
      const alertIndex = alerteCopy.findIndex(
        (alerte) => alerte.id === payload.id
      );

      if (alertIndex !== -1) {
        // Remplacer l'alerte existante par la nouvelle dans la copie
        alerteCopy[alertIndex] = payload;
        alerteCopy.pop();
      } else {
        // Ajouter une nouvelle alerte à la copie
        alerteCopy.push(payload);
      }

      // Mettre à jour state.user.alerte avec la copie modifiée
      state.user.alerte = alerteCopy;
    },
    // favorite
    addFavoris(state, payload) {
      state.user.favorie_job = [
        ...state.user.favorie_job,
        { job_offer: payload },
      ];
    },
    deleteFavoris(state, payload) {
      state.user.favorie_job = state.user.favorie_job.filter(
        (job) => job.job_offer.id !== payload
      );
    },
    setLoggedIn(state, payload) {
      state.isLoggedIn = payload;
    },
  },
  actions: {
    async fetchUser({ commit }) {
      try {
        let userData = await getUser();
        if (!userData || !userData.type_user) {
          commit('setLoggedIn', false);
          commit('resetUser');
          return;
        }
        if (userData.type_user === 'recruiter') {
          const recruteurFavorites = await isCandidateFavoriteById();
          userData = { ...userData, recruteurFavorites };
        }
        commit('updateUser', { type: null, payload: userData });
        commit('setLoggedIn', true);
      } catch (error) {
        commit('setLoggedIn', false);
        commit('resetUser');
        console.log('error store fetchUser', error);
      }
    },

    /**
     * handle user change in store
     * @param {String} type - type of user data : alert, candidature, conversation, favoris
     * - set to null for whole user object
     * @param {Object} payload - user data
     */
    handleUserChange({ commit }, { type, payload }) {
      commit('updateUser', { type, payload });
    },
    handleUserRoleChange({ commit }, role) {
      commit('updateUserRole', role);
    },
    handleDeleteUserPropreties({ commit }, { type, payload }) {
      commit('deleteUserPropreties', { type, payload });
    },
    logout({ commit }) {
      console.log('Action logout appelée');
      // Vérifie si le token d'accès est absent
      if (!localStorage.getItem('access_token')) {
        console.log("Aucun token d'accès, réinitialisation de l'utilisateur");
        commit('deleteUser'); // Réinitialise l'état de l'utilisateur
        return; // Sort de l'action
      }
    },
    // alert
    /**
     * Add a new alert to the user's alert list
     * @param {Object} payload - The alert to add
     * @returns {void}
     * */
    handleAddAlert({ commit }, payload) {
      commit('addAlert', payload);
    },

    // favorite
    /**
     * Add a job offer to the user's favorite list
     * @param {Object} payload - The job offer to add
     * @returns {void}
     */
    handleAddFavoris({ commit }, payload) {
      commit('addFavoris', payload);
    },
    /**
     * Remove a job offer from the user's favorite list
     * @param {Object} payload - The job ID offer to remove
     * @returns {void}
     * */
    handleDeleteFavoris({ commit }, payload) {
      commit('deleteFavoris', payload);
    },

    handleChangeAlert({ commit }, payload) {
      commit('updateAlert', payload);
    },
  },
  getters: {
    getUser: (state) => {
      if (!state.user) return {};
      const userCopy = { ...state.user };
      // sort conversation by date modification
      if (Array.isArray(userCopy.conversation)) {
        userCopy.conversation = [...userCopy.conversation].sort((a, b) => {
          return new Date(b.date_modification) - new Date(a.date_modification);
        });
      }
      return userCopy;
    },
    userRole: (state) => {
      return state.user?.type_user || '';
    },
  },
};
