<template>
  <section v-if="receiver">
    <div ref="chatSection" class="chat-section">
      <div>
        <!-- Chat Header -->
        <div class="chat-header">
          <div class="chat-header-left">
            <img
              :src="recipientAvatar"
              alt="Recipient Avatar"
              class="company-avatar"
            />
            <p class="company-name">
              {{ recipientName || 'Sélectionner un destinataire' }}
            </p>
          </div>
          <div class="chat-header-right">
            <!-- Options -->
            <img
              src="@/assets/viseo.svg"
              v-if="!isVideoVisible"
              alt="Start Video Call"
              class="chat-header-icon yellow-border"
              @click="startVideoCall"
            />
            <img
              src="@/assets/call.svg"
              alt="Start Call"
              class="chat-header-icon yellow-border"
            />
            <img
              src="@/assets/icons/eye-border-orange.svg"
              @click="onClick"
              alt="Active Call"
              class="chat-header-icon yellow-border"
            />
            <img
              src="@/assets/dots-black.svg"
              alt="More Options"
              class="chat-header-icon black-bg"
            />
          </div>
        </div>

        <!-- Chat Messages -->
        <div
          class="chat-messages"
          id="id_chat_item_container"
          ref="messagesContainer"
        >
          <div
            v-for="(message, index) in messages"
            :key="index"
            :class="{
              'chat-message company-message right':
                message.sender_name === currentName,
              'chat-message user-message left':
                message.sender_name !== currentName,
            }"
          >
            <div class="message-content">
              <div class="row">
                <span class="message-username">{{ message.sender_name }}</span>
                <span class="message-timestamp">{{ message.time }}</span>
              </div>
              <div class="row">
                <span class="message-text">{{ message.message }}</span>
                <span class="message-timestamp">{{ message.date }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Chat Input -->
    <div class="chat-input-section">
      <div class="textarea-container">
        <textarea
          v-model="messageInput"
          @keyup.enter="sendMessage"
          placeholder="Saisis ton message"
        ></textarea>
      </div>
      <div class="icons-and-send">
        <div class="icons-row">
          <img
            src="@/assets/text-black.svg"
            alt="Text Icon"
            class="input-icon"
          />
          <img
            src="@/assets/more-black.svg"
            alt="More Options"
            class="input-icon"
          />
          <img
            src="@/assets/at-black.svg"
            alt="Mention Icon"
            class="input-icon"
          />
          <img
            src="@/assets/pj-black.svg"
            alt="Attach Icon"
            class="input-icon"
          />
          <img
            src="@/assets/emoji-black.svg"
            alt="Emoji Icon"
            class="input-icon"
          />
        </div>
        <button class="send-button" @click="sendMessage">
          <img
            src="@/assets/icons/icon-send-msg-primary1.svg"
            alt="Send Icon"
          />
        </button>
      </div>
    </div>
  </section>

  <!-- Sidebar pour l'historique des chats et utilisateurs -->
  <aside class="messaging-chat-history-section" v-if="!isVideoVisible">
    <!-- Liste des utilisateurs -->
    <div class="user-list">
      <header>
        <span
          v-if="isInLayout"
          class="toggle-users-list"
          @click="toggleChatOpen"
        >
          <i v-if="isChatOpen" class="fas fa-chevron-down"></i>
          <i v-else class="fas fa-chevron-up"></i>
        </span>
        <h3>Amis</h3>
      </header>
      <ul
        v-show="isChatOpen"
        v-if="users.length > 0"
        class="user-list"
        ref="userList"
        @scroll="onScroll"
      >
        <li
          v-for="user in users"
          :key="user.id"
          @click="selectUser(user)"
          :class="{ selected: user.id === receiver }"
          class="user-item"
        >
          <div
            class="connection-indicator"
            :class="{ online: user.connected, offline: !user.connected }"
            :title="user.connected ? 'Connecté' : 'Déconnecté'"
          ></div>
          <img
            v-if="user.photo"
            :src="user.photo"
            alt="User Avatar"
            class="user-avatar"
          />
          <img
            v-else
            :src="
              require('../../assets/search/search-page-jobcard-emptylogo-icon.jpg')
            "
            alt="User Avatar"
            class="user-avatar"
          />

          <div class="flex">
            <div class="user-info">
              <span class="user-name title"
                >{{ user.first_name }} {{ user.last_name }}</span
              >
              <span
                v-if="unreadMessages[user.first_name + ' ' + user.last_name]"
                class="unread-badge"
                >!</span
              >
            </div>
            <div>
              <span class="user-job job">{{ user.metier }}</span>
            </div>
          </div>
        </li>
      </ul>
      <p v-else>Aucun utilisateur trouvé</p>
    </div>
  </aside>
</template>

<script>
  import axios from 'axios';
  import { axiosInstance } from '../../services/axios';

  export default {
    props: {
      // Spécifie si le composant appartient au layout ou si c'est le composant dans la page messagerie
      isInLayout: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        // Si le composant n'appartient pas au layout, on affiche le chat ouvert par défaut, sinon on se base sur l'état du localStorage
        isChatOpen: this.isInLayout ? false : true,
        chatSocket: null,
        isSocketOpen: false,
        messageInput: '',
        messages: [],
        receiver: '',
        users: [],
        selectedTab: 0,
        conversations: [],
        chatMessages: {},
        previousMessages: [],
        currentPage: 1,
        totalPages: 1,
        isLoading: false,
        unreadMessages: {},
      };
    },
    computed: {
      currentName() {
        const { first_name, last_name } = this.getUser || {};
        return `${first_name || ''} ${last_name || ''}`.trim();
      },
      currentId() {
        return this.getUser?.id || '';
      },
      recipientName() {
        const recipient = this.users.find((user) => user.id === this.receiver);
        return recipient
          ? `${recipient.first_name} ${recipient.last_name}`
          : '';
      },
      recipientId() {
        const recipient = this.users.find((user) => user.id === this.receiver);
        return recipient ? recipient.id : null;
      },
      recipientAvatar() {
        const recipient = this.users.find((user) => user.id === this.receiver);
        return (
          recipient?.photo ||
          require('@/assets/search/search-page-jobcard-emptylogo-icon.jpg')
        );
      },
      getUser() {
        return this.$store.getters.getUser;
      },
    },
    methods: {
      toggleChatOpen() {
        this.isChatOpen = !this.isChatOpen;
        if (this.isInLayout) {
          localStorage.setItem('isChatOpen', this.isChatOpen);
        }
        this.receiver = '';
      },
      onClick() {
        const id1 = this.currentId;
        const idRecipient = this.recipientId;
        this.$router.push(
          idRecipient === id1
            ? `/utilisateur/${id1}`
            : `/utilisateur/${idRecipient}`
        );
      },
      async fetchUsers(page = 1) {
        if (this.isLoading || page > this.totalPages) return;
        this.isLoading = true;

        try {
          const response = await axiosInstance.get(
            `/best_profils/?page=${page}`
          );
          this.users = [...this.users, ...response.data.results];
          console.log('users', this.users);

          this.totalPages = Math.ceil(response.data.count / 9);
          this.currentPage = page;
        } catch (error) {
          console.error(
            'Erreur lors de la récupération des utilisateurs :',
            error
          );
        } finally {
          this.isLoading = false;
        }
      },
      goToPage(page) {
        if (page > 0 && page <= this.totalPages) {
          console.log('Changement de page :', page);
          this.fetchUsers(page);
        } else {
          console.log('Page invalide ou déjà à la dernière page');
        }
      },
      onScroll() {
        const list = this.$refs.userList;
        if (list.scrollTop + list.clientHeight >= list.scrollHeight - 10) {
          if (this.currentPage < this.totalPages) {
            this.fetchUsers(this.currentPage + 1);
          }
        }
      },
      async selectUser(user) {
        this.receiver = user.id;

        if (this.chatSocket) {
          this.chatSocket.close();
          this.chatSocket = null;
        }
        this.initializeWebSocket(this.currentId, this.receiver);
      },
      async fetchConversations(id1, id2) {
        try {
          const response = await axios.get(
            `https://websocket.thanks-boss.com/conversation/${id1}/${id2}/`
          );

          const newMessages = response.data.map((msg) => ({
            sender_name: `${msg.sender.first_name} ${msg.sender.last_name}`,
            message: msg.message,
            time: this.formatTime(msg.time),
            date: this.formatDate(msg.date),
            read: msg.read,
          }));
          console.log('msg', newMessages);

          const messagesHaveChanged = !this.areMessagesEqual(
            newMessages,
            this.messages
          );

          if (messagesHaveChanged) {
            this.messages = newMessages.reverse();
            this.playNotificationSound();

            newMessages.forEach((message) => {
              if (!message.read && message.sender_name !== this.currentId) {
                this.unreadMessages[message.sender_name] = true;
                // this.markAsRead(message);
              }
            });
          }
        } catch (error) {
          console.error('Erreur lors de la récupération des messages :', error);
        }
      },
      areMessagesEqual(newMessages, oldMessages) {
        if (newMessages.length !== oldMessages.length) return false;

        for (let i = 0; i < newMessages.length; i++) {
          if (newMessages[i].message !== oldMessages[i].message) {
            return false;
          }
        }
        return true;
      },
      async markAsRead(message) {
        try {
          const response = await axiosInstance.post(
            `https://websocket.thanks-boss.com/read/${this.currentId}/${this.receiver}/`
          );
          console.log('reponse', response);

          const msg = this.messages.find((m) => m === message);
          if (msg) {
            msg.read = true;
            delete this.unreadMessages[message.sender_name];
          }
        } catch (error) {
          console.error(
            'Erreur lors de la mise à jour du statut du message :',
            error
          );
        }
      },
      getNormalizedPath(id1, id2) {
        const [minId, maxId] = [id1, id2].sort((a, b) => a - b);
        return `${minId}/${maxId}/`;
      },
      initializeWebSocket(id1, id2) {
        this.fetchConversations(this.currentId, this.receiver);
        const path = this.getNormalizedPath(id1, id2);
        const url = `wss://websocket.thanks-boss.com/ws/chat/${path}`;
        this.chatSocket = new WebSocket(url);

        this.chatSocket.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (data.type === 'chat') {
            this.messages.push({
              sender_name: `${data.sender.first_name} ${data.sender.last_name}`,
              message: data.message,
              time: this.formatTime(new Date()),
            });
            if (data.sender.id !== this.currentId) {
              this.unreadMessages[data.sender_name] = true;
            }
          }
          this.fetchConversations(this.currentId, this.receiver);
        };
      },
      sendMessage() {
        if (this.chatSocket && this.chatSocket.readyState === WebSocket.OPEN) {
          const message = {
            sender_id: this.currentId,
            message: this.messageInput.trim(),
          };
          this.chatSocket.send(JSON.stringify(message));

          const now = new Date();
          const formattedTime = `${now
            .getHours()
            .toString()
            .padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;

          this.messages.push({
            sender_name: this.currentName,
            message: this.messageInput.trim(),
            time: this.formatTime(formattedTime),
          });
          this.messageInput = '';
        }
      },
      formatTime(time) {
        if (time instanceof Date) {
          const hours = time.getHours().toString().padStart(2, '0');
          const minutes = time.getMinutes().toString().padStart(2, '0');
          return `${hours}:${minutes}`;
        } else if (typeof time === 'string') {
          const [hours, minutes] = time.split(':');
          return `${hours}:${minutes}`;
        }
        throw new Error('Invalid time format');
      },
      formatDate(date) {
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        const todayStr = today.toISOString().split('T')[0];
        if (date === todayStr) {
          return "aujourd'hui";
        } else if (date === yesterdayStr) {
          return 'hier';
        }
        const [year, month, day] = date.split('-');
        return `${day}-${month}-${year}`;
      },
      playNotificationSound() {
        const audio = new Audio(require('@/assets/sound/sound2.wav'));
        audio.addEventListener('error', (e) => {
          console.error("Erreur lors du chargement de l'audio :", e);
        });
        audio.play().catch((error) => {
          console.error('Erreur lors de la lecture du son :', error);
        });
      },
    },
    mounted() {
      this.fetchUsers(this.currentPage);
      if (this.isInLayout) {
        const savedState = localStorage.getItem('isChatOpen');
        this.isChatOpen = savedState === 'true';
      }
    },
    beforeDestroy() {
      if (this.chatSocket) {
        this.chatSocket.close();
      }
      localStorage.removeItem('isChatOpen');
    },
  };
</script>

<style scoped>
  .unread-badge {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: red;
    display: inline-block;
    margin-left: 5px;
  }
  .job {
    color: #7f7272;
  }
  .title {
    font-size: 18px;
    font-weight: 600;
  }
  .flex {
    display: flex;
    flex-direction: column;
  }
  aside {
    width: 350px;
  }
  section {
    width: 500px;
  }
  .user-list {
    position: relative;
    width: 100%;
    background-color: #ffffff;
    border-right: 1px solid #ffffff;
    overflow-y: auto;
  }
  .user-list header {
    border-bottom: 1px solid #ccc;
    min-height: 48px;
    padding: 15px;
  }
  .toggle-users-list {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 0;
    transform: translateY(50%);
  }

  .user-list h3 {
    font-size: 18px;
    font-weight: bold;
    color: black;
  }

  .user-list ul {
    list-style: none;
    padding: 0;
    max-height: 72vh;
    overflow: auto;
  }

  .user-list li {
    display: flex;
    align-items: flex-start;
    padding: 7px;
    cursor: pointer;
    transition: background-color 0.3s;
    flex-direction: row;
  }

  .user-list li:hover {
    background-color: #eee;
  }
  .connection-indicator {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: relative;
    left: 16px;
    top: 33px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3),
      inset 0 -2px 4px rgba(0, 0, 0, 0.5);
    background: radial-gradient(circle, #ffffff 10%, #e0e0e0 70%, #9e9e9e 100%);
  }
  .connection-indicator.online {
    background: radial-gradient(circle, #00ca10 10%, #4caf50 70%, #2e7d32 100%);
  }

  .connection-indicator.offline {
    background: radial-gradient(circle, #afafaf 10%, #9e9e9e 70%, #616161 100%);
  }

  .user-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .message-content {
    display: flex;
    flex-direction: column;
  }
  .message-username {
    font-weight: bold;
    margin-bottom: 4px;
  }

  .message-timestamp {
    font-size: 0.75em;
    color: gray;
    padding-left: 12px;
  }
  .chat-container {
    background-color: #fff;
    height: auto;
    border-radius: 5px;
  }

  .chat-section {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    width: 100%;
    max-height: 60vh;
    min-height: 60vh;
  }

  .chat-header {
    display: flex;
    position: sticky;
    top: 0rem;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    background: #ffffffd9;
  }

  .chat-header-left {
    display: flex;
    align-items: center;
  }
  .chat-header-right {
    display: flex;
    align-items: center;
  }
  .chat-header-right img {
    padding: 5px;
  }

  .company-avatar {
    width: 48px;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #dfdbd6;
    margin: 15px;
    object-fit: cover;
  }

  .company-name {
    font-size: 18px;
    font-weight: bold;
    color: black;
  }

  .chat-title p {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    padding-left: 16px;
  }

  .chat-messages {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px;
    overflow-y: auto;
  }

  .message {
    max-width: 60%;
    padding: 10px 16px;
    border-radius: 10px 10px 10px 0px;
    margin-bottom: 10px;
    display: inline-block;
    word-wrap: break-word;
  }

  .user-message {
    align-self: flex-start;
    background-color: #f1f1f1;
    color: black;
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }

  .company-message {
    align-self: flex-end;
    background-color: var(--yellow-100);
    color: black;
    border-radius: 10px 10px 0 10px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }

  .chat-input-section {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-top: 1px solid #ccc;
    position: sticky;
    bottom: 0rem;
  }

  .chat-input-section textarea {
    width: 100%;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid var(--gray-300);
    background-color: #fdf5e6;
    font-size: 14px;
    resize: none;
    min-height: 80px;
  }

  .icons-and-send {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }

  .icons-row {
    display: flex;
    gap: 10px;
  }

  .input-icon {
    width: 32px;
    height: 32px;
    gap: 6px;
    background-color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s ease;
  }
  .input-icon:hover {
    background-color: var(--yellow-100);
  }

  .send-button {
    width: 32px;
    height: 32px;
    background-color: var(--yellow-100);
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
</style>
