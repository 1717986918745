import { baseUrl } from "@/services/axios";

/**
 * getImgPath
 * @param {string|File} path - The path of the image, or a File object.
 * @return {string} - The full URL of the image.
 */
const getImgPath = (path) => {
  // Si path est vide, retourne l'image par défaut
  if (!path) {
    return require("@/assets/home/home-section-news-article1.png");
  }

  // Si path est un objet File (image téléchargée)
  if (path instanceof File) {
    return URL.createObjectURL(path);
  }

  // Si le chemin commence par 'http' ou 'https', il s'agit déjà d'une URL complète
  if (typeof path === 'string' && (path.startsWith('http://') || path.startsWith('https://'))) {
    return path;
  }

  // Sinon, on le complète avec baseUrl
  return baseUrl + path;
};

export default getImgPath;
