<template>
  <nav>
    <div :class="['navbar', userTypeClass]">
      <div class="img-container" @click="gotoPage('/')">
        <div class="img-container-circle">
          <img src="@/assets/logo-tb.svg" alt="logo" />
        </div>
      </div>

      <!-- mobile only -->
      <div id="mobile">
        <div class="hamburger-container" @click="toggleMenu">
          <img
            v-if="isMenuOpen"
            src="@/assets/icons/close-btn.svg"
            alt="liste icône"
          />
          <img v-else src="@/assets/icons/hamburger.svg" alt="liste icône" />
        </div>
      </div>

      <!-- menu -->
      <ul :class="isMenuOpen ? 'menu' : 'menu hidden'">
        <li
          v-if="isLoggedIn && userRole === 'applicant'"
          :class="{
            active: activePage === 'search',
            'margin-left': !isLoggedIn,
          }"
          @click="gotoPage('/recherche')"
        >
          Offres d'emploi
        </li>
        <li
          v-if="isLoggedIn"
          :class="{
            active: activePage === 'recruiter-search',
            'margin-left': !isLoggedIn,
          }"
          @click="gotoPage('/communaute')"
        >
          Communauté
        </li>

        <li
          :class="{ active: activePage === 'social' }"
          @click="gotoPage('/reseau-social')"
        >
          Réseau social
        </li>

        <li
          v-if="isLoggedIn && userRole === 'recruiter'"
          :class="{ active: activePage === 'recruiter-messaging' }"
          @click="gotoPage('/recruteur/messagerie')"
        >
          Messagerie
        </li>
        <li
          v-if="isLoggedIn && userRole === 'applicant'"
          :class="{ active: activePage === 'call' }"
          @click="gotoPage('/messagerie')"
        >
          Messagerie
        </li>
        <li
          v-if="isLoggedIn && userRole === 'applicant'"
          :class="{ active: activePage === 'messaging' }"
          @click="gotoPage('/chat-ia')"
        >
          Chat IA emploi
        </li>

        <li
          :class="{ active: activePage === 'blog' }"
          @click="gotoPage('/actualites')"
        >
          Actualités
        </li>

        <li
          v-if="isLoggedIn && userRole === 'applicant'"
          :class="{ active: activePage === 'prices' }"
          @click="gotoPage('/tarifs')"
        >
          Tarifs
        </li>

        <li
          v-if="isLoggedIn && userRole === 'recruiter'"
          :class="{ active: activePage === 'recruiter-prices' }"
          @click="gotoPage('/recruteur/tarifs')"
        >
          Tarifs
        </li>

        <li
          v-if="!isLoggedIn"
          :class="{ active: activePage === 'login', 'grey-bg': true }"
          @click="gotoPage('/connexion')"
        >
          S'inscrire / Se connecter
        </li>
        <li
          v-else
          :class="{ active: activePage === 'account', avatar: true }"
          @click="toggleSubMenu"
        >
          <div class="avatar-img">
            <UserAvatar v-if="user" :user="user" :width="40" />
          </div>
          Mon compte
          <img
            v-if="isSubMenuOpen"
            src="@/assets/icons/arrow-up.svg"
            alt="arrow-up"
          />
          <img v-else src="@/assets/Icon-black-54.svg" alt="arrow-down" />
        </li>
      </ul>
    </div>

    <!-- submenu -->
    <ul v-if="isSubMenuOpen" class="submenu">
      <!-- 
        <li 
          :class="{ 'active': activePage === 'applications' }" 
          @click="gotoPage('/applications')"
        >
          Candidatures
        </li>
        -->

      <li
        v-if="isLoggedIn && userRole === 'recruiter'"
        :class="{ active: activePage === 'recruiter-dashboard' }"
        @click="gotoPage('/recruteur/tableau-de-bord')"
      >
        Tableau de bord
      </li>
      <li
        v-else-if="isLoggedIn"
        :class="{ active: activePage === 'dashboard' }"
        @click="gotoPage('/tableau-de-bord')"
      >
        Tableau de bord
      </li>

      <!--
        <li 
          :class="{ 'active': activePage === 'posts' }" 
          @click="gotoPage('/posts')"
        >
          Posts et activités
        </li>
        -->

      <li
        v-if="isLoggedIn && userRole === 'recruiter'"
        :class="{ active: activePage === 'recruiter-offer' }"
        @click="gotoPage('/recruteur/offres')"
      >
        Mes offres
      </li>

      <li
        v-if="isLoggedIn && userRole === 'recruiter'"
        :class="{ active: activePage === 'recruiter-favorite' }"
        @click="gotoPage('/recruteur/favoris')"
      >
        Favoris
      </li>
      <li
        v-else-if="isLoggedIn"
        :class="{ active: activePage === 'favorite' }"
        @click="gotoPage('/favoris')"
      >
        Favoris
      </li>

      <li
        v-if="isLoggedIn && userRole === 'applicant'"
        :class="{ active: activePage === 'Alerts' }"
        @click="gotoPage('/alertes')"
      >
        Alertes
      </li>
      <li
        v-if="isLoggedIn && userRole === 'applicant'"
        :class="{ active: activePage === 'candidatures' }"
        @click="gotoPage('/candidatures')"
      >
        Mes candidatures
      </li>

      <!-- 
        <li 
          :class="{ 'active': activePage === 'subscription' }"
          @click="gotoPage('/abonnements')"
        >
          Abonnement
        </li>
        -->
      <li
        v-if="isLoggedIn && userRole === 'recruiter'"
        :class="{ active: activePage === 'profil-recruiter' }"
        @click="gotoPage('/recruteur/profil')"
      >
        Mon profil
      </li>
      <li
        v-else-if="isLoggedIn"
        :class="{ active: activePage === 'profil' }"
        @click="gotoPage('/profil')"
      >
        Mon profil
      </li>

      <li
        :class="{ active: activePage === 'settings' }"
        @click="gotoPage('/parametres')"
      >
        Paramètres
      </li>
    </ul>
  </nav>
</template>

<script>
  import { mapGetters } from 'vuex';
  import gotoPage from '@/utils/router';
  import { baseUrl } from '../../../services/axios';
  import UserAvatar from '../../views-models/profil/UserAvatar.vue';

  export default {
    name: 'NavbarApp',
    components: {
      UserAvatar,
    },
    data() {
      return {
        activePage: this.$route.name || 'home', // Set active page for lasting hover effect ("breadcrumb")
        isMenuOpen: false, // Toggle state of navbar for navigation
        isSubMenuOpen: false, // Toggle state of second navbar for profile navigation
        user: this.getUser || {},
      };
    },
    async mounted() {
      try {
        this.photoUrl = this.getUser.photo;
      } catch (error) {
        console.error('Error fetching profile section data:', error);
      }
    },
    computed: {
      ...mapGetters(['isLoggedIn', 'getUser', 'userRole']),

      userTypeClass() {
        const currentUser = this.getUser;
        return currentUser.type_user === 'recruiter'
          ? 'navbar-recruiter'
          : 'navbar';
      },
    },
    watch: {
      '$route.name'(newName) {
        this.activePage = newName;
      },
      getUser: {
        immediate: true,
        handler(newUser) {
          this.user = newUser || {};
        },
      },
    },
    methods: {
      // Set active page for breadcrumb and use vue router to switch page
      gotoPage(page) {
        this.activePage = page;
        gotoPage(page);
      },

      // Toggle menu for navigation, close submenu if open
      toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
        if (this.isMenuOpen) this.isSubMenuOpen = false; // Optionally close submenu
      },

      // Toggle sub menu for profile navigation, close menu if open
      toggleSubMenu() {
        this.isSubMenuOpen = !this.isSubMenuOpen;
        if (this.isSubMenuOpen) this.isMenuOpen = false; // Optionally close menu
      },
    },
  };
</script>

<style scoped>
  img {
    height: 50px;
    width: 50px;
  }

  ul {
    height: 100%;
    width: 100%;
    gap: 10px;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    justify-content: space-around;
  }

  li {
    width: 100%;
    height: 56px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 60px;
    cursor: pointer;
    transition: background-color 0.3s ease-out;
    &.active {
      background-color: var(--primary-1b2);
    }
  }

  li:hover {
    background-color: var(--gray-light);
  }

  .navbar {
    min-height: 80px;
    max-height: 100vh;
    padding-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--navbar-bg-color);
    color: var(--navbar-font-color);
    overflow: hidden;
  }

  .navbar-recruiter .img-container-circle {
    background-color: var(--yellow-100);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    width: 64px;
  }

  .img-container {
    height: 100%;
    min-width: 205px;
    display: flex;
    align-items: center;
    padding-left: 15px;
    cursor: pointer;
  }

  .avatar img:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }

  .avatar img {
    height: 30px;
    width: 30px;
  }

  .avatar-img {
    border-radius: 50%;
    border: 2px solid var(--gray-light);
    margin-right: 10px;
  }

  .grey-bg {
    background-color: var(--gray-light);
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  /* hamburger & avatar */
  .hamburger-container {
    /*position: absolute;
  top: 10px;
  right: 10px;*/
    position: relative;
    top: -10px;
    cursor: pointer;
  }

  .hamburger-container:hover {
    background-color: var(--primary-1b2);
  }

  #mobile {
    position: absolute;
    top: 20px;
    right: 0px;
    display: flex;
    gap: 5px;
  }

  #avatar {
    display: flex;
    height: 30px;
  }

  /* second navbar for profile navigation */
  .submenu {
    background-color: var(--navbar-bg-color);
    color: var(--navbar-font-color);
    height: 62vh;
    align-items: center;
    width: 100%;
    padding-inline: 15%;
    padding-block: 20px;
  }

  .submenu li {
    background-color: var(--gray-light);
    border-radius: 5px;
    padding-inline: 16px;
    padding-block: 24px;
    width: 150px;
    height: 50px;
    &.active {
      background-color: var(--primary-1b2);
    }
  }

  .submenu li:hover {
    background-color: var(--primary-1b2);
  }

  .menu {
    background-color: var(--navbar-bg-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 62vh;
    z-index: 100;
  }

  .hidden {
    display: none;
  }

  @media screen and (min-width: 992px) {
    ul {
      flex-direction: row;
    }

    li {
      justify-content: center;
      padding-left: 0px;
    }

    .navbar {
      flex-direction: row;
      align-items: center;
      padding-top: 0px;
    }

    .menu {
      position: initial;
      display: flex;
      flex-direction: row;
      height: fit-content;
      min-height: 80px;
    }

    .submenu {
      height: 80px;
    }

    .hamburger-container {
      display: none;
    }

    .img-container {
      justify-content: center;
      padding-left: 0px;
    }

    #mobile {
      display: none;
    }
  }

  @media screen and (min-width: 1800px) {
    .navbar {
      padding: 0 15%;
      margin: auto;
    }
  }

  @media screen and (min-width: 2000px) {
    .navbar {
      padding: 0 25%;
      margin: auto;
    }

    .navbar-recruiter .img-container-circle {
      background-color: var(--yellow-100);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 64px;
      width: 64px;
    }
  }
</style>
