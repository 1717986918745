import axios from "axios";
import store from "../store";
import { logout } from "./account.service";

export const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;
export const apiBaseUrl = `${baseUrl}/api`;

export let accessToken = localStorage.getItem('access_token');


/**
 * Save access token in localStorage
 * @param {*} Token
 */
export function setAccessToken(value) {
  // On afecte la variable accessToken pour l'utiliser dans la config de axios
  accessToken = value;
  localStorage.setItem('access_token', value);
}
/**
 * Get access token from localStorage
 * @returns {string} access token
 */
export function getAccessToken() {
  return localStorage.getItem('access_token');
}
// Cette logique correspond à celle de refresh_token dans les localStorage
export function getRefreshToken() {
  return localStorage.getItem('refresh_token');
}
export function setRefreshToken(value) {
  localStorage.setItem('refresh_token', value);
}

// ⚠️ Cette logique correspond à celle de refresh_token dans les cookies
// ⚠️ On ne l'utilise pas pour le moment
/*
export function setRefreshToken(value) {
  document.cookie = `refresh_token=${value}; path=/; secure; samesite=strict; max-age=2592000`; // 30 jours
}
export function getRefreshToken() {
  try {
    return document.cookie
      .split('; ')
      .find(row => row.startsWith('refresh_token='))
      ?.split('=')[1] || null;
  } catch (error) {
    console.warn('Error getting refresh token from cookies', error);
    return null;
  }
}
*/

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((config) => {
  const csrfToken = getCookie("csrftoken");
  if (csrfToken) {
    config.headers["X-CSRFToken"] = csrfToken;
  }

  if (accessToken && accessToken !== 'null') {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  
  if (
    config.data instanceof FormData &&
    config.headers["Content-Type"] === "application/json"
  ) {
    config.headers["Content-Type"] = "multipart/form-data";
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const authenticationStatusCodes = new Set([401, 403]);
    const loginUrl = "/user/token/";

    const { config, response: { status } } = error;

    if (
      !authenticationStatusCodes.has(status) ||
      config.url === loginUrl ||
      store.getters.isLoggedIn === false
    ) {
      return Promise.reject(error);
    }

    // Déconnecte l'utilisateur si une erreur d'authentification est détectée
    await logout();
    return Promise.reject(error);
  }
);

/**
 
@deprecated use axiosInstance directly */
export default function axiosRequest(_contentType = "application/json") {
  return axiosInstance;
}